'use client';

import { css, cx } from '@pt-frontends/styled-system/css';
import {
  footer,
  mainNavigationItem,
  mobileNavigation
} from '@pt-frontends/styled-system-web/recipes';
import { Icon } from '@ui/icon';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@ui/select';
import { useRouter } from 'next/navigation';

import { LANGUAGES_TRANS } from '@lib/config';
import { Cms_SitemapAlternateLang } from '@lib/services/cms';
import { Locale } from '@lib/services/i18n';

const trigger = css({
  border: 'none',
  p: 0,
  outline: 'none',
  bg: 'transparent'
});

const headerLangSelect = css({
  color: 'white',
  bg: 'transparent',
  p: '0!',
  border: 'none!',
  fontWeight: 'semibold'
});

const headerContainer = css({
  borderLeft: '1px solid',
  borderColor: 'white/70',
  ml: 4,
  pl: 7,
  pr: 1,
  minW: '72px'
});

interface LanguageNavigationProps {
  position: 'footer' | 'header' | 'mobile';
  languages: Locale[];
  current: Locale;
  fallback: Locale;
  alternateLangs?: Cms_SitemapAlternateLang[];
}

const LanguageNavigation: React.FC<LanguageNavigationProps> = ({
  languages,
  fallback,
  current,
  alternateLangs,
  position
}) => {
  const { push } = useRouter();
  const handleSelect = (lng: Locale) => {
    if (lng === current) return;

    const alt = alternateLangs?.find(lang => lang.locale === lng);

    if (!alt || !alt.path) {
      // eslint-disable-next-line no-console
      console.warn('No alternate language content found for', lng);
      push(`/${lng}`);
    }

    if (!alt) return;

    push(alt.path);
  };

  if (languages.length === 0) {
    return null;
  }

  if (position === 'mobile') {
    if (languages.length < 2) {
      return null;
    }

    return (
      <div
        className={css({
          ml: -4,
          w: 'screen',
          mb: -4
        })}
      >
        <Select value={current} onValueChange={handleSelect}>
          {/* <SelectTrigger
            noIcon
            className={cx('group', mobileNavigation().navItemLabel, mobileTrigger)}
          >
            <SelectValue />
          </SelectTrigger> */}
          <SelectTrigger
            noIcon
            className={cx(
              'group',
              mainNavigationItem().itemTrigger,
              css({
                fontSize: 'block',
                bg: 'grey.bg',
                rounded: '0px!',
                border: 'none!',
                outline: 'none!',
                py: 3,
                display: 'flex',
                justifyContent: 'flex-start'
              })
            )}
          >
            <SelectValue />
            <Icon
              i="angle-down-circle"
              size="small"
              className={cx(mainNavigationItem().navIcon, css({ color: 'primary' }))}
            />
          </SelectTrigger>
          <SelectContent
            className={css({
              w: 'calc(100dvw - 32px)',
              borderRadius: '0',
              border: 'none',
              shadow: 'none',
              zIndex: 99999
            })}
            align="start"
          >
            {languages.map(lang => (
              <SelectItem
                key={lang}
                value={lang}
                className={cx(
                  mobileNavigation().navItemLabel,
                  css({
                    borderTop: '1px solid {colors.grey.light}',
                    py: 3,
                    _last: {
                      pb: 1.5
                    },
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    bg: 'transparent !important'
                  })
                )}
              >
                {LANGUAGES_TRANS[lang] || lang}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    );
  }

  if (position === 'header') {
    if (languages.length < 2) {
      return null;
    }

    return (
      <div className={headerContainer}>
        <Select value={current} onValueChange={handleSelect}>
          <SelectTrigger
            noIcon
            className={cx('group', mainNavigationItem().itemTrigger, headerLangSelect)}
          >
            {LANGUAGES_TRANS[current]}
            <Icon i="angle-down-circle" size="small" className={mainNavigationItem().navIcon} />
          </SelectTrigger>
          <SelectContent
            className={css({ zIndex: 99999, borderRadius: 'sm' })}
            align="end"
            sideOffset={10}
          >
            {languages.map(lang => (
              <SelectItem key={lang} value={lang}>
                {LANGUAGES_TRANS[lang] || lang}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    );
  }

  if (languages.length === 1) {
    const key = languages[0] || fallback;

    return LANGUAGES_TRANS[key] || null;
  }

  return (
    <Select value={current} onValueChange={handleSelect}>
      <SelectTrigger className={cx(footer({ isLang: true }).bottomNavItem, trigger)} noIcon>
        {LANGUAGES_TRANS[current]}
      </SelectTrigger>
      <SelectContent>
        {languages.map(lang => (
          <SelectItem key={lang} value={lang}>
            {LANGUAGES_TRANS[lang] || lang}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export { LanguageNavigation };
export type { LanguageNavigationProps };
