'use client';

import React, { useEffect, useState } from 'react';

import { css, cva, cx } from '@pt-frontends/styled-system/css';
import { mobileNavigation } from '@pt-frontends/styled-system-web/recipes';
import * as DialogRx from '@radix-ui/react-dialog';
import { Icon } from '@ui/icon';
import { MenuButton } from '@ui/menuButton';

import { useHeaderBanner } from '@lib/hooks';
import { Cms_FlyoutMenu, Cms_SitemapTreeItem } from '@lib/services/cms';
import { useTranslation } from '@lib/services/i18n/client';

import { LanguageNavigation, LanguageNavigationProps } from '@web/languageNavigation';

import { useMobileNavStore } from './mobileNavigationStore';
import { MobileSubItems } from './MobileSubItems';
import { NavItemMobile } from './NavItemMobile';

const contentSlider = cva({
  base: {
    transition: 'transform 0.3s ease-out',
    transform: 'translateX(100%)',
    opacity: 0,
    h: 0,
    visibility: 'hidden'
  },
  variants: {
    hasCurrentSubMenu: {
      true: {
        transform: 'translateX(0%)',
        opacity: 1,
        h: 'auto',
        visibility: 'visible'
      }
    },
    isBackClicked: {
      true: {
        animation: 'exitToRight 400ms cubic-bezier(0.16, 1, 0.3, 1)',
        animationDelay: '0.2s',
        transform: 'translateX(100%)'
      }
    }
  }
});

// const backWrap = css({
//   display: 'flex',
//   alignItems: 'center',
//   pb: 8,
//   borderBottom: { base: '1px solid {colors.grey.light}', md: 'none' }
// });

// const backLabel = css({
//   color: 'black',
//   fontSize: '18px',
//   fontWeight: '500',
//   lineHeight: '18px'
// });

// const backIcon = css({
//   w: 4,
//   h: 4,
//   color: 'primary',
//   mr: '6px',
//   mt: '5px'
// });

type MobileNavigationProps = {
  flyoutMenus?: Cms_FlyoutMenu[];
  items?: Cms_SitemapTreeItem[];
  activeItemId?: number | undefined;
  hasHeaderBanner?: boolean;
  langNavProps?: LanguageNavigationProps;
};

const MobileNavigation: React.FC<MobileNavigationProps> = ({
  items,
  flyoutMenus,
  activeItemId,
  hasHeaderBanner,
  langNavProps
}) => {
  const [isBackClicked, setIsBackClicked] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<false | undefined>(undefined);
  const setCurrentSubMenuContent = useMobileNavStore(state => state.setCurrentSubMenuContent);
  const currentSubMenuContent = useMobileNavStore(state => state.item);
  const hasCurrentSubMenu = currentSubMenuContent !== null;
  const isBannerActive = useHeaderBanner(hasHeaderBanner);
  const { t } = useTranslation();
  const { overlay, content, contentInner, backWrap, backLabel, backIcon } = mobileNavigation({
    isBannerActive,
    hasCurrentSubMenu,
    isBackClicked
  });

  const handleSubmenuAppereance = (item: Cms_SitemapTreeItem) => {
    setCurrentSubMenuContent(item);
    setIsBackClicked(false);
  };

  const handleBackClick = () => {
    setIsBackClicked(true);
    setTimeout(() => {
      setCurrentSubMenuContent(null);
    }, 300);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1280) {
        setIsOpen(false);

        return;
      }

      if (window.innerWidth < 1280 && isOpen === false) {
        setIsOpen(undefined);

        return;
      }
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isOpen]);

  return (
    <DialogRx.Root open={isOpen}>
      <div>
        <DialogRx.Trigger asChild>
          <MenuButton />
        </DialogRx.Trigger>
      </div>

      <DialogRx.Portal>
        <DialogRx.Overlay className={overlay} />
        <DialogRx.Content
          className={cx(content, css({ display: 'flex', flexDir: 'column', zIndex: 9999999999 }))}
        >
          <div className={contentInner}>
            {items?.map(item => (
              <NavItemMobile
                data={item}
                key={item.id}
                handleSubmenuAppereance={handleSubmenuAppereance}
                activeItemId={activeItemId}
              />
            ))}
          </div>

          <div
            className={contentSlider({
              isBackClicked,
              hasCurrentSubMenu
            })}
          >
            <div className={backWrap} onClick={() => handleBackClick()}>
              <Icon i="chevron-left" size="small" className={backIcon} />
              <span className={backLabel}>{t('uiWeb.mobileNav.back')}</span>
            </div>
            <MobileSubItems
              data={currentSubMenuContent}
              depth={0}
              activeItemId={activeItemId}
              flyoutMenus={flyoutMenus}
              itemId={currentSubMenuContent?.id}
            />
          </div>
          {langNavProps && (
            <div className={css({ mt: 'auto' })}>
              <LanguageNavigation {...langNavProps} position="mobile" />
            </div>
          )}
        </DialogRx.Content>
      </DialogRx.Portal>
    </DialogRx.Root>
  );
};

export { MobileNavigation };
export type { MobileNavigationProps };
