import { getBlogPostBySlug, getBlogPosts } from './api/blogPosts/blogPosts.queries';
import { getFlyoutMenus } from './api/flyoutMenus/flyoutMenus.queries';
import { getIconByName, getIcons } from './api/icons/icons.queries';
import { getPageById, getPages } from './api/pages/pages.queries';
import { getPartners } from './api/partners/partners.queries';
import { abortSearch, searchContents } from './api/search/search.queries';
import { getDocsSitemap, getSitemaps, getSitemapTrees } from './api/sitemaps/sitemaps.queries';
import { getTeamMembers } from './api/teamMembers/teamMember.queries';
import { getTenantById, getTenants } from './api/tenants/tenants.queries';

const cmsService = {
  pages: {
    getPageById,
    getPages
  },
  tenants: {
    getTenants,
    getTenantById
  },
  sitemaps: {
    getSitemaps,
    getSitemapTrees,
    getDocsSitemap
  },
  flyoutMenus: {
    getFlyoutMenus
  },
  partners: {
    getPartners
  },
  blogPosts: {
    getBlogPosts,
    getBlogPostBySlug
  },
  icons: {
    getIcons,
    getIconByName
  },
  teamMembers: {
    getTeamMembers
  },
  search: {
    abortSearch,
    searchContents
  }
};

export { cmsService as cms };
