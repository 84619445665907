import { mergeDeep } from '../../../../utils';

import {
  populateBlogPosts,
  populateBrandsBanner,
  populateCTA,
  populateCaseStudyTeaser,
  populateContactForm,
  populateDownloadForm,
  populateDownloads,
  populateEcosystem,
  populateHeader,
  populateImage,
  populateList,
  populateParallaxList,
  populatePartners,
  populatePricing,
  populateQuote,
  populateTableEditor,
  populateTeam,
  populateTeaserBig,
  populateTeasersSmall,
  populateText,
  populateTextImage,
  populateTitleAccordion,
  populateTitleText,
  populateUsps
} from './blocks';
import { populateMeta } from './blocks/populateCommon';
import { populateVideo } from './blocks/video';

const populateContent = () => {
  let populate = {};
  populate = mergeDeep(populate, populateText);
  populate = mergeDeep(populate, populateHeader);
  populate = mergeDeep(populate, populateTitleText);
  populate = mergeDeep(populate, populateTeaserBig);
  populate = mergeDeep(populate, populateTeasersSmall);
  populate = mergeDeep(populate, populateUsps);
  populate = mergeDeep(populate, populateCaseStudyTeaser);
  populate = mergeDeep(populate, populateCTA);
  populate = mergeDeep(populate, populateTitleAccordion);
  populate = mergeDeep(populate, populateImage);
  populate = mergeDeep(populate, populateEcosystem);
  populate = mergeDeep(populate, populateTextImage);
  populate = mergeDeep(populate, populateDownloads);
  populate = mergeDeep(populate, populateQuote);
  populate = mergeDeep(populate, populateList);
  populate = mergeDeep(populate, populateContactForm);
  populate = mergeDeep(populate, populateDownloadForm);
  populate = mergeDeep(populate, populatePricing);
  populate = mergeDeep(populate, populatePartners);
  populate = mergeDeep(populate, populateBlogPosts);
  populate = mergeDeep(populate, populateVideo);
  populate = mergeDeep(populate, populateTeam);
  populate = mergeDeep(populate, populateBrandsBanner);
  populate = mergeDeep(populate, populateParallaxList);
  populate = mergeDeep(populate, populateTableEditor);

  return populate;
};

export const populatePage = {
  id: true,
  content: {
    ...populateContent()
  },
  title: true,
  meta: {
    ...populateMeta
  },
  sitemapNodes: true
};
