/* eslint-disable @typescript-eslint/naming-convention */
'use client';

import React, { useState } from 'react';

import { css, cx } from '@pt-frontends/styled-system/css';
import { Grid, GridItem } from '@pt-frontends/styled-system/jsx';
import { cmsContentDownloadForm } from '@pt-frontends/styled-system-web/recipes';
import { useInView } from 'react-intersection-observer';

import { Cms_ContentDownloadForm } from '@lib/services/cms';
import { SmtpConfig } from '@lib/services/contact';

import DownloadContentInner from './DownloadContentInner';
import DownloadFormInner from './DownloadFormInner';
import DownloadFormTermsDialog from './DownloadFormTermsDialog';

interface DownloadFormProps {
  data: Cms_ContentDownloadForm;
  formId: string;
  hasCaptcha?: boolean;
  smtpConfig?: SmtpConfig;
  userEmail?: string;
  userCompany?: string;
  userName?: string;
}

const DownloadForm: React.FC<DownloadFormProps> = props => {
  const { data, formId } = props;
  const { containerInner, gridItem } = cmsContentDownloadForm();
  const [isTermsOpen, setTermsOpen] = useState(false);
  const isTermsEnabled = data.terms && data.terms.checkboxLabel && data.terms.content;
  const { ref, inView } =
    useInView(/* {
    threshold: 0.1
  } */);

  return (
    <>
      <Grid gap={{ base: 4, md: 8 }} columns={{ base: 1, lg: 2 }} className={containerInner}>
        <GridItem className={cx(gridItem, css({ minH: 450, h: 'auto !important', p: 0 }))}>
          <DownloadContentInner data={data} formId={formId} />
        </GridItem>
        <GridItem
          id={formId}
          className={cx(gridItem, css({ minH: 450, h: 'auto !important' }))}
          ref={ref}
        >
          {inView && (
            <DownloadFormInner {...props} isTermsOpen={isTermsOpen} setTermsOpen={setTermsOpen} />
          )}
        </GridItem>
      </Grid>

      {isTermsEnabled && isTermsOpen && data.terms?.content && (
        <DownloadFormTermsDialog
          data={data.terms?.content}
          isOpen={isTermsOpen}
          setOpen={setTermsOpen}
        />
      )}
    </>
  );
};

export { DownloadForm };
export type { DownloadFormProps };
