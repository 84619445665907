'use client';

import React from 'react';

import { cx } from '@pt-frontends/styled-system/css';
import { mainNavigationItem } from '@pt-frontends/styled-system-web/recipes';
import * as NavRx from '@radix-ui/react-navigation-menu';
import { CmsNavLink } from '@ui/cmsLink';
import { Icon } from '@ui/icon';

import type { Cms_FlyoutMenu, Cms_SitemapTreeItem } from '@lib/services/cms';

import { FlyoutMenu } from '../flyoutMenu';

interface NavItemContentProps {
  data: Cms_SitemapTreeItem;
  hasChildren: boolean;
  activeItemId?: number;
  flyoutMenu?: Cms_FlyoutMenu;
}

const NavItemContent: React.FC<NavItemContentProps> = ({
  data,
  hasChildren,
  activeItemId,
  flyoutMenu
}) => {
  const { itemTrigger, line, navIcon } = mainNavigationItem();
  if ((data.type === 'internal' || data.type === 'directory') && hasChildren) {
    return (
      <>
        <NavRx.Trigger className={cx('group', itemTrigger)} value={data.id}>
          <span className={mainNavigationItem({ isActive: data.id === activeItemId }).label}>
            {data.title}
          </span>
          <Icon i="angle-down-circle" size="small" className={navIcon} />
          <span className={line} />
        </NavRx.Trigger>
        <FlyoutMenu forceMount data={data} flyoutMenu={flyoutMenu} activeItemId={activeItemId} />
      </>
    );
  }

  return (
    <CmsNavLink navItem={data} className={cx('group', itemTrigger)}>
      <span className={mainNavigationItem({ isActive: data.id === activeItemId }).label}>
        {data.title}
      </span>
    </CmsNavLink>
  );
};

interface NavitemProps {
  data: Cms_SitemapTreeItem;
  flyoutMenu?: Cms_FlyoutMenu;
  activeItemId?: number;
}

const NavItem = React.forwardRef<HTMLLIElement, NavitemProps>((props, ref) => {
  const { navItem } = mainNavigationItem();
  const { data, flyoutMenu, activeItemId, ...restProps } = props;
  const hasChildren =
    data.children.length > 0 &&
    data.children.filter(item => item.active && item.include.includes('header')).length > 0;

  return (
    <NavRx.Item ref={ref} className={navItem} {...restProps}>
      <NavItemContent
        data={data}
        hasChildren={hasChildren}
        activeItemId={activeItemId}
        flyoutMenu={flyoutMenu}
      />
    </NavRx.Item>
  );
});
NavItem.displayName = 'NavItem';

export { NavItem, NavItemContent };
export type { NavItemContentProps, NavitemProps };
