import Link from 'next/link';

import { Cms_EditorNodeData } from '@lib/services/cms';
import { HtmlTagName } from '@lib/types';

import { DynamicTag } from '@ui/dynamicTag';

import EditorNestedLink from './EditorNestedLink';

interface CmsEditorNodeProps {
  as?: HtmlTagName;
  data: Cms_EditorNodeData;
  isNestedLinks?: boolean;
}

const CmsEditorNode: React.FC<CmsEditorNodeProps> = ({ data, isNestedLinks, as }) => {
  const element = data as Cms_EditorNodeData;

  const getLinkChild = (child: any) => {
    if (child.type === 'a') {
      return { ...child, type: 'span' };
    }

    return child;
  };

  const getMark = (el: any) => {
    const cl: string[] = [];

    if (el.bold === true) {
      cl.push('font-bold bold');
    }

    if (el.italic === true) {
      cl.push('font-italic italic');
    }

    if (el.align === 'center') {
      cl.push('text-center align-center');
    }

    if (el.align === 'end') {
      cl.push('text-right align-right');
    }

    if (el.mark) {
      cl.push('mark');
    }

    if (cl.length === 0) {
      return undefined;
    }

    return cl.join(' ');
  };

  if (element.type === undefined && element.text === '') {
    return null;
  }

  if (element.text) {
    return (
      <span
        className={getMark(element)}
        dangerouslySetInnerHTML={{
          __html: element.text?.replace(/(?:\r\n|\r|\n)/g, '<br />')
        }}
      />
    );
  }

  if (element.type === 'a') {
    if (!isNestedLinks) {
      if (element.data?.type === 'INTERNAL') {
        if (!element.data.openInNewTab) {
          return (
            <Link className="ps-editor-link" href={element._url || '/'}>
              {element.children?.map((child: any, index: number) => (
                <CmsEditorNode data={getLinkChild(child)} key={index} />
              ))}
            </Link>
          );
        }
      }

      return (
        <a
          className="ps-editor-link"
          href={element._url || element.data?.value}
          rel="noreferrer"
          target={element.data?.openInNewTab ? '_blank' : '_self'}
        >
          {element.children?.map((child: any, index: number) => (
            <CmsEditorNode data={getLinkChild(child)} key={index} />
          ))}
        </a>
      );
    }

    return <EditorNestedLink data={element} />;
  }

  let type: HtmlTagName | undefined = element.type === 'lic' ? 'span' : element.type;

  if (type && type === 'p' && as && as !== 'p') {
    type = as;
  }

  return (
    <DynamicTag tagName={type?.toLowerCase() as HtmlTagName}>
      {element.children?.map((child: any, index: number) => (
        <CmsEditorNode data={child} key={index} isNestedLinks={isNestedLinks} />
      ))}
    </DynamicTag>
  );
};

export default CmsEditorNode;
